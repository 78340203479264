import React, { useEffect, useState } from 'react';
import Header from '../components/header'
import Footer from '../components/footer';
import { Helmet } from "react-helmet";


const PartnersPage = () => {

     // Client-side Runtime Data Fetching
   const [partnersObj, setPartnersObj] = useState([])
   useEffect(() => {
       // get data from GitHub api
       fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/partners/?per_page=8`)
           .then(response => response.json()) // parse JSON from request
           .then(resultData => {
            setPartnersObj(resultData);
           }) // parse JSON from request

   }, [])


   return (
    <div className='container-fluid'>
        <Header/>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Partners - Productlabs</title>
            <link rel="canonical" href="https://productlabs.us/" />
            <meta property="og:title" content="Partners - Productlabs" />
            <meta property="og:description" content="VETTED EXPERTS TO HELP EXECUTE PRODUCT BUILD" />
        </Helmet>
        <div className='partner-detail-page'>
            <div id="partners" className="partner-section">
                <div className="container">
                    <div className="heading-wrapper">
                        <div className="heading-common-wrap">
                            <h2>Partners</h2>
                        </div>
                    </div>
                    <div className="partner-logo-grid">
                        {partnersObj.map((partner,index) => (
                            <div className="logoGrid" key={index}>
                                <div className="logoImage">  
                                <a href={partner.acf.partner_website_url} target="_blank">
                                    <img src={partner.acf.partners_image_logo.url} alt="partner-logo"></img>
                                </a>                          
                                                           
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                     
        </div>
        </div>
        <Footer/>
    </div>
)
}
export default PartnersPage;